import styled, { css } from "styled-components";
import { SaveButton } from "../InputGoals/styles";
import { StyledTabs } from "../CompanyDashBoard/styles";

const BoldFont = "Rubik-Bold";
const RegularFont = "Rubik-Regular";

const StyledTab = styled(StyledTabs)`
  margin-top: 20px;

  > ul {
    margin: ${({ margin }) => margin};
  }
`;
const Layout = styled.div`
  padding: 32px 25px 20px 15px;
  width: 100%;
  float: left;
  font-family: ${RegularFont};
  margin-bottom: 100px;
`;

const PageTitle = styled.div`
  font-size: ${({ font }) => (font ? font : "24px")};
  color: #0D4270;
  /* display: inline-block; */
  /* float: left; */
  margin-bottom: 15px;
  font-family: rubik-medium;
  line-height: 21px;
  margin-top: ${({ margin }) => (margin ? margin : "0px")};
`;

const CreateEventButton = styled(SaveButton)`
  background: ${({ bgColor }) =>
    bgColor ? "linear-gradient(180deg,#52A8ED 0%,#61C8FB 100%)" : "#159fc9"};
  border: ${({ bgColor }) => (bgColor ? "none" : "1px solid #159fc9")};
  color: white;
  white-space: nowrap;
  padding: 10px 30px;
  font-size: 13px;
  border-radius: ${({ bgColor }) => (bgColor ? "4px" : "30px")};
  text-transform: uppercase;
  font-family: ${BoldFont};
  float: right;
  width: 150px;
  ${({ addRightMargin }) =>
    addRightMargin &&
    css`
      margin-right: ${addRightMargin};
    `}

  &:focus, &:active, &:hover {
    outline: none;
  }

  ${({ isBigButton }) =>
    isBigButton &&
    css`
      width: auto;
      padding: 15px 20px;
      margin: ${({ customMargin }) => customMargin && customMargin};

      @media (max-width: 550px) {
        margin-top: 15px;
      }
    `}
`;

const CreateEventDownloadButton = styled(SaveButton)`
  position: relative;
  top: 55px;
  right: 25px;
  background: ${({ bgColor }) =>
    bgColor ? "linear-gradient(180deg,#52A8ED 0%,#61C8FB 100%)" : "#159fc9"};
  border: ${({ bgColor }) => (bgColor ? "none" : "1px solid #159fc9")};
  color: white;
  white-space: nowrap;
  padding: 10px 30px;
  font-size: 13px;
  border-radius: ${({ bgColor }) => (bgColor ? "4px" : "4px")};
  text-transform: uppercase;
  font-family: ${BoldFont};
  float: right;
  width: auto;
  z-index: 5;
  ${({ addRightMargin }) =>
    addRightMargin &&
    css`
      margin-right: ${addRightMargin};
    `}

  &:focus, &:active, &:hover {
    outline: none;
  }

  ${({ isBigButton }) =>
    isBigButton &&
    css`
      width: auto;
      padding: 15px 20px;
      margin: ${({ customMargin }) => customMargin && customMargin};

      @media (max-width: 550px) {
        margin-top: 15px;
      }
    `}
`;

const EventContainer = styled.div`
  float: left;
  width: calc(100% / 3);
  margin-top: 20px;
  background-color: white;
  box-shadow: 1px 1.732px 46px rgba(0, 0, 0, 0.18);
  position: relative;
  height: 430px;
  @media (max-width: 1200px) and (min-width: 767px) {
    height: unset;
    display: flex;
  }

  @media (max-width: 767px) {
    height: auto;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  float: left;
  height: 100%;
  cursor: pointer;

  @media (max-width: 1200px) and (min-width: 768px) {
    object-fit: cover;
  }
`;

const EventContentContainer = styled.div`
  padding: 20px 15px 14px;
  float: left;
  width: 45%;
  display: inline-block;
  height: 100%;

  @media (max-width: 767px) and (min-width: 530px) {
    width: 50%;
    height: 280px;
  }

  @media (max-width: 530px) {
    width: 100%;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    margin-left: 30%;
  }
`;

const EventDate = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 28px;
  margin-top: 10px;
  float: left;
  width: 100%;

  i {
    margin-right: 3px;
  }

  > div {
    float: left;
    width: 100%;
    > svg {
      width: 16px;
      margin-right: 4px;
      height: 15px;
      margin-top: 7px;
      float: left;
    }
  }

  span:last-child {
    color: rgb(21, 159, 202);
    font-family: ${BoldFont};
  }
`;

const EventContent = styled.div`
  float: left;
  display: inline-block;
  width: 100%;

  > span {
    text-transform: capitalize;
    cursor: pointer;
    font-size: 18px;
    letter-spacing: 0px;
    color: rgb(51, 51, 51);
    font-family: ${BoldFont};
    float: left;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    text-overflow: ellipsis;
    max-height: 50px;
    line-height: 30px;
  }
`;

const AddCalenderButton = styled(SaveButton)`
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0;
  font-size: 12px;
  background-color: ${(props) => props.color || "#ef6827"};
  border: ${(props) =>
    props.color ? `1px solid ${props.color}` : " 1px solid #ef6827"};
  width: 175px;
  height: 35px;
  font-family: ${BoldFont};
  display: block;
  text-align: center;
  margin-left: auto;
  &:focus,
  &:active,
  &:hover {
    outline: none;
  }

  > i {
    margin-right: 5px;
  }

  > span {
    float: left;
  }

  > svg {
    float: left;
    margin-right: 5px;
    width: 16px;
    height: 15px;
    margin-top: 2px;
  }

  ${({ rsvpButton }) =>
    rsvpButton &&
    css`
      height: 34px;
      width: auto;
      padding: 0 20px;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed !important;
      opacity: 0.5;
      width: auto;
      padding: 0 20px;
    `}

  ${({ joinButton }) =>
    joinButton &&
    css`
      @media (max-width: 770px) {
        padding: 10px 20px;
        width: auto;
        height: auto;
      }
    `}

  ${({ calendar }) =>
    calendar &&
    css`
      padding-left: 18px;
    `}
  @media(max-width: 400px) {
    margin: 20px auto;
  }
`;

const EventJoiningTime = styled.div`
  float: left;
  width: 100%;
  margin-top: 20px;
  > div {
    > span {
      color: #ef6827;
      font-family: ${BoldFont};
    }
  }
`;

const DescriptionContainer = styled.div`
  float: right;
  width: 25%;
  display: inline-block;
  padding: 25px 27px 16px 15px;
  height: 100%;

  @media (min-width: 951px) and (max-width: 1010px) {
    padding: 25px 20px 16px 10px;
  }

  > span {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgb(51, 51, 51);
    font-family: ${BoldFont};
  }

  > div {
    color: rgb(153, 153, 153);
    margin-top: 5px;
    height: 100px;
    overflow: hidden;
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box !important;

    @media (max-width: 766px) {
      height: auto;
      display: block;
    }
  }

  @media (max-width: 766px) {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  float: left;
  width: 30%;
  height: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  @media (max-width: 1200px) and (min-width: 768px) {
    height: auto;
  }

  @media (max-width: 767px) and (min-width: 530px) {
    width: 50%;
    height: 280px;
  }

  @media (max-width: 530px) {
    width: 100%;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    position: absolute;
    height: 100% !important;
  }

  > img {
    width: 100%;
    float: left;
    height: 100%;
    cursor: pointer;

    @media (max-width: 1200px) and (min-width: 768px) {
      object-fit: cover;
    }
  }
`;

const EventsContainer = styled.div`
  float: left;
  -ms-overflow-style: none;
  font-family: ${RegularFont};
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: ${({ height }) => (height ? "900px" : "none")};
  overflow-y: ${({ overflow }) => (overflow ? "auto" : "none")};
  ::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c7c8c7;
    cursor: pointer;
    border-radius: 2.5px;
  }

  ::-webkit-scrollbar-track {
    background: white;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  > div:first-child {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    max-width: 1246px;
    margin: 0 25px;
    padding: ${({ padding }) => (padding ? padding : "25px 0px 0 15px")};
  }
`;

const MainEventsContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: auto;
  > div:first-child {
    width: 100%;
    max-width: 1246px;
    margin: 25px;
    margin-bottom: 0px;
    margin-top: ${({ margin, marginTop }) =>marginTop ? "25px" : margin ? "0px" : "0px"};
    display: ${({ margin }) => (margin ? "flex" : "")};
    justify-content: ${({ margin }) => (margin ? "flex-end" : "")};
  }
`;

const CompleteButton = styled.div`
  display: flex;
  width: 100%;
  background: white;
  cursor: pointer;
  border-radius: 6px;
  margin-top: 15px;
  margin-bottom: 25px;
  > div:first-child {
    width: 20%;
    padding: 5px 0px >img {
      width: auto;
      height: 48px;
      margin: auto;
      display: flex;
      margin-left: 5px;
    }
  }
  > div:last-child {
    width: 80%;
    display: flex;
    margin: auto;
    font-size: 18px;
    line-height: 24px;
    font-family: rubik-medium;
    justify-content: center;
    padding-right: 35px;
    color: #0D4270;
  }
`;

const CreateButton = styled.div`
  width: calc((100% - 70px) / 3);
  display: flex;
  background: linear-gradient(
    90deg,
    rgb(0, 177, 188) -1.11%,
    rgb(117, 217, 163) 97.46%
  );
  cursor: pointer;
  border-radius: 6px;
  > div:first-child {
    width: 20%;
    > img {
      width: 40px;
      height: 40px;
      margin: 5px;
    }
  }
  > div:last-child {
    width: 80%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-family: rubik-medium;
    color: white;
    margin: auto;
    margin-right: 20px;
  }
`;

const OndemandContainer = styled.div`
  width: 100%;
  padding: 0 25px;
  > div {
    width: 100%;
    max-width: 1246px;
    margin: auto;
    padding-top: 25px;
  }
`;

const EventsContainerMain = styled.div`
  width: 100%;
  display: flex;
  height: ${({ height }) => (height ? height : "none")};
`;

const EventsContainerNew = styled.div`
  width: 100%;
  padding-right: 25px;
  display:flex;
`;

const CreateButtonCotainer = styled.div`
  width: 30.9%;
  margin-top: ${({ margin }) => (margin ? "-35px" : "0px")};
`;

const CreateButtonNew = styled.div`
  width: 100%;
  display: flex;
  background: linear-gradient(
    90deg,
    rgb(0, 177, 188) -1.11%,
    rgb(117, 217, 163) 97.46%
  );
  background: ${({ bgColor }) =>
    bgColor
      ? "linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%)"
      : "#FD7175"};
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 15px;
  > div:first-child {
    width: 20%;
    > img {
      width: 40px;
      height: 40px;
      margin: 5px;
    }
  }
  > div:last-child {
    width: 80%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-family: rubik-medium;
    color: white;
    margin: auto;
    margin-right: 35px;
  }
`;

const Description = styled.div`
  padding: ${({ padding }) => (padding ? "0px" : "0 15px")};
  text-align: ${({ textAlign }) => (textAlign ? "left" : "justify")};
  > div {
    > p {
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      text-align: justify;
      color: #3a3a3a;
    }
  }
`;

const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  margin: ${({ margin }) => (margin ? "12px 0 10px 0" : "0")};
  > div:first-child {
    justify-content: flex-start;
    width: 50%;
  }
  > div:last-child {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    margin: auto;
    margin-bottom: 15px;
  }
`;

const SeeAllButton = styled.div`
  display: flex;
  justify-content: flex-end;

  font-family: Rubik;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #fd7175;
  cursor: pointer;
`;

const ViewAllButton = styled.div`
  width: 21%;
  max-width: 125px;
  height: 30px;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  background: rgba(253, 113, 117, 1);
  cursor: pointer;
  align-items: center;

  font-family: Rubik;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
`;

const ViewAllText = styled.div`
  font-family: Rubik;
  font-size: 16px;
  line-height: 24px;
  color: #007AB1;
  cursor:pointer;
  display:flex;
  align-items:center;
  >svg{
    width:16px;
    height:16px;
  }
`;

const TotalPoints = styled.div`
  width: 100%;
  display: flex;
  /* background: #ffffff; */
  border-radius: 6px;
  margin-bottom: 15px;
  > div:first-child {
    width: 100%;
    /* height: 120px; */
    /* max-width: 163px; */
    /* > img {
      height: 120px;
      width: 100%;
    } */
  }
  > div:last-child {
    width: 57.5%;
    margin: auto;
    > div:first-child {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #0D4270;
      padding-bottom: 15px;
      text-transform: none;
      text-align: center;
    }
    > div:last-child {
      font-family: Rubik-Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 40px;
      color: #fd7175;
      text-align: center;
    }
  }
`;

const EventPointCard = styled.div`
  float: left;
  width: ${({ width }) => (width ? width : "100%")};
  display: inline-block;
  font-family: "rubik";
  margin-bottom: 16px;
  .card {
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    .flex {
      display: flex;
      width: 100%;
      .block {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 5px 10px 5px 5px;
        .imgWidth {
          width: 30px;
        }
        > img {
          width: 50px;
          height: 50px;
        }
      }
      .text {
        font-size: 12px;
        letter-spacing: 0px;
        color: #333333;
        font-family: "Rubik-Medium";
        text-align: left;
        padding-right: 0px;
        font-family: Rubik-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: #0D4270;
        margin: auto;
        margin-left: 0px;
        text-transform: none;
      }
    }
  }
  @media (max-width: 800px) {
    height: 200px;
  }
`;

const NumberFont = styled.div`
  display: flex;
  justify-content: flex-end;
  letter-spacing: 0px;
  color: rgb(105, 194, 255);
  font-family: "Rubik";
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  font-family: Rubik-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  margin: auto;
  margin-right: 15px;
`;

const EventPointsWrapper = styled.div`
display: flex;
width: 100%;
flex-wrap: wrap;
margin-bottom: ${({ bottom, margin }) => (margin?"0px": bottom ? "25px" : "15px")};
margin-top: ${({ top }) => (top ? "15px" : "")};
justify-content:${({ justify }) => (justify ? "space-between" : "")};
gap:${({ gap }) => (gap ? "8px" : "")};

`;

const CategoryCard = styled.div`
display: flex;
width:calc((100% - 40px) / 6);
justify-content: center;
height: 80px;
padding:10px 15px 10px 15px;
background: ${({ bgColor }) => (bgColor ? "#007AB1" : "white")};
cursor:pointer;
border-radius:6px;
border: ${({ bgColor }) => (bgColor ? "2px solid #007AB1" : '')};
box-shadow: ${({ shadow }) => (shadow ? "none" : "")};
align-items: center;
margin-bottom:4px;

img {
  height:30px;
  width:30px;
  border-radius: 3px;
}

.categoryName{
  color: ${({ bgColor }) => (bgColor ? "white" : "#007AB1")};
  font-family: ${({ bgColor }) => (bgColor ? "rubik-medium" : "rubik-medium")};
  font-size: 16px;
  line-height: 24px;
  padding-left:15px;
  margin: auto 0 auto 0px; 
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
`;

const CompletedChallengeWrapper = styled.div`
display: flex;
width: 100%;
flex-wrap: wrap;
height: ${({ height }) => (height ? "900px" : "none")};
overflow-y: ${({ overflow }) => (overflow ? "auto" : "none")};
.challengeCard{
  width: 100%;
}
.eventPointsCard{
  width: 30.9%;
}
::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #c7c8c7;
  cursor: pointer;
  border-radius: 2.5px;
}

::-webkit-scrollbar-track {
  background: white;
}
`;

const StyledSvg = styled.svg`
  height: ${({height}) => height ? height : '30px'};
  width: ${({width}) => width ? width : '30px'};
  float: left;
  fill: ${({ showCompany, show }) => show?show:showCompany ? 'transparent' : '#999999'};
  padding: ${({ padding }) => padding ? padding : '2px 0px 0px'};
  margin: ${({marginTop}) => marginTop && marginTop };
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'}
  pointer-events: none;
  >svg{
    >path{
      fill: ${({ active,activeValue }) => activeValue?activeValue:active ? 'white' : '#999999'};
    }
    >g{
      >path{
        fill: ${({ active,activeValue }) => activeValue?activeValue:active ? 'white' : '#999999'};
      }
      >g{
        fill: ${({ active,activeValue }) => activeValue?activeValue:active ? 'white' : '#999999'};
      }
    }
  }
`;

const StyledSvgNew = styled.svg`
  height: ${({height}) => height ? height : '30px'};
  width: ${({width}) => width ? width : '30px'};
  float: left;
  fill: ${({ showCompany, show }) => show?show:showCompany ? 'transparent' : '#007AB1'};
  padding: ${({ padding }) => padding ? padding : '2px 0px 0px'};
  margin: ${({marginTop}) => marginTop && marginTop };
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'}
  pointer-events: none;
  >svg{
    >path{
      fill: ${({ active,activeValue }) => activeValue?activeValue:active ? 'white' : '#007AB1'};
    }
    >g{
      >path{
        fill: ${({ active,activeValue }) => activeValue?activeValue:active ? 'white' : '#007AB1'};
      }
      >g{
        fill: ${({ active,activeValue }) => activeValue?activeValue:active ? 'white' : '#007AB1'};
      }
    }
  }
`;

const SliderContainer = styled.div`
  width: 100%;

  .slick-slider {
    width: ${({width}) => width ? width : '100%'};
    margin: ${({marginValue}) => marginValue ? marginValue : '20px 0 10px'};
    padding: ${({padding}) => padding ? padding : ''};
    box-shadow: none;

    .slick-slide{
      margin: 0px;
      display: flex;
      justify-content: center;
      display: ${({display}) => display ? display : 'block'};
      justify-content: ${({content}) => content ? content : ''};
      margin: ${({margin}) => margin ? margin : ''};
      // width: 400px !important;
      @media (max-width: 600px){
        margin: 0 auto;
      }
    }
    .slick-prev {
      display:none !important;
    }

    .slick-next {
      display:none !important;
    }
      [dir="rtl"] .slick-next:before {
        content: "‹";
      }
  }
`;

const SliderContainerOndemand = styled.div`
  width: 100%;

  .slick-slider {
    width: ${({width}) => width ? width : '100%'};
    margin: ${({marginValue}) => marginValue ? marginValue : '20px 0 10px'};
    padding: ${({padding}) => padding ? padding : ''};
    box-shadow: none;

    .slick-slide{
      margin: 0px;
      display: flex;
      // justify-content: center;
      display: ${({display}) => display ? display : 'block'};
      // justify-content: ${({content}) => content ? content : ''};
      margin: ${({margin}) => margin ? margin : ''};
      // width: 400px !important;
      @media (max-width: 600px){
        margin: 0 auto;
      }
    }
    .slick-prev {
      display:none !important;
    }

    .slick-next {
      display:none !important;
    }
      [dir="rtl"] .slick-next:before {
        content: "‹";
      }
  }
`;

const ReservedButton = styled.div`
  height: ${({height}) => height ? height : '48px'};
  width: ${({width}) => width ? width : '340px'};
  display:flex;
  background: ${({active}) => active ? "#007AB1" : 'white'};
  justify-content:center;
  align-items:center;
  border-radius: 4px;
  cursor:pointer;
  border-radius:6px;
  color: #FFF;
  color: ${({active}) => active ? "#FFF" : '#007AB1'};
  border: ${({active}) => active ? "none" : '1px solid #007AB1'};
  text-align: center;
  font-family: Rubik-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  .icon{
    width:24px;
    height:24px;
    margin-right:12px;
    >img{
      width:100%;
      height:100%;
    }
  }
`;

export {
  CreateEventButton,
  EventContainer,
  EventContentContainer,
  EventDate,
  EventContent,
  AddCalenderButton,
  EventJoiningTime,
  DescriptionContainer,
  StyledImage,
  ImageContainer,
  Layout,
  PageTitle,
  EventsContainer,
  TitleContainer,
  StyledTab,
  CreateEventDownloadButton,
  MainEventsContainer,
  CompleteButton,
  OndemandContainer,
  CreateButton,
  EventsContainerMain,
  EventsContainerNew,
  CreateButtonNew,
  CreateButtonCotainer,
  Description,
  HeadingContainer,
  SeeAllButton,
  ViewAllButton,
  TotalPoints,
  EventPointCard,
  NumberFont,
  EventPointsWrapper,
  ViewAllText,
  CategoryCard,
  CompletedChallengeWrapper,
  StyledSvg,
  StyledSvgNew,
  SliderContainer,
  ReservedButton,
  SliderContainerOndemand
};